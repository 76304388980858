<template>
  <section>
      <div>
        {{ reservations.appointments }}
      </div>

      <div>
        <h3>trié</h3>
        <p>{{ sortedAppointment }}</p>
      </div>
  </section>
</template>

<script>
import { dateMixin } from '../mixins/date';
import axios from 'axios';
export default {
  name: "test",
  mixins:[dateMixin],
  display: "Clone",
  order: 2,
  components: {
  },
  data() {
    return {
      my_sites : ['akory'],
      reservations:{
          "ulid": "01H5S12MMHRRGD93D295QNPFNC",
          "reservation_date": "2023-07-19T00:00:00+02:00",
          "created_at": "2023-07-20T00:00:00+02:00",
          "user": {
              "ulid": "01FJ1RX663SKW9TDJ9P7Y0WNDJ",
              "pseudo": "lauriechonion",
              "email": "contact@ailem.fr",
              "firstname": "lauriechonion",
              "lastname": "lauriechonion",
              "tel": ""
          },
          "staff": {
              "ulid": "01FJ1RX663SKW9TDJ9P7Y0WNDJ",
              "pseudo": "lauriechonion",
              "email": "contact@ailem.fr",
              "firstname": "lauriechonion",
              "lastname": "lauriechonion",
              "tel": ""
          },
          "status": "validate",
          "descriptions": "réservationlauriechonion",
          "on_behalf_of": {
              "name": "lauriechonion",
              "phone": "+33675437522",
              "email": "contact@monechappeebelle.fr"
          },
          "address": null,
          "appointments": [
              {
                  "ulid": "01H5S12MMQWEYRV92Q0Q0K0Q7N",
                  "start_hour": "2023-07-19T18:15:00+02:00",
                  "end_hour": "2023-07-19T19:20:00+02:00",
                  "service": {
                      "ulid": "01FRP5BC6Q24QRSE3CQ430SHZJ",
                      "name": "Jambes complètes",
                      "price": 25,
                      "duration": 0.67,
                      "tags": [
                          "Femme"
                      ]
                  },
                  "created_at": "2023-07-20T00:00:00+02:00"
              },
              {
                  "ulid": "01H5S12MMQWEYRV92Q0Q0K0Q7P",
                  "start_hour": "2023-07-19T18:00:00+02:00",
                  "end_hour": "2023-07-19T18:15:00+02:00",
                  "service": {
                      "ulid": "01FRP53MJ9AM4BSNRZQN7XC34F",
                      "name": "Aisselles",
                      "price": 13,
                      "duration": 0.25,
                      "tags": [
                          "Femme"
                      ]
                  },
                  "created_at": "2023-07-20T00:00:00+02:00"
              }
          ]
      }
    }
  },
  computed: {
    
  },
  watch:{
  },
  methods:{
    sortedAppointment() {
      //trier les appointments par date (décroissant)
      let sortedAppointments = this.reservations.appointments.sort((appointment1, appointment2) => new Date(appointment1.start_hour) - new Date(appointment2.start_hour))
      console.log({sortedAppointments});
      return sortedAppointments
    },
    getMySites(){
      axios.get('mysites')
      .then(mySites => this.my_sites = mySites)
      .catch(errmySites => console.log(errmySites))
    }
  },
  created() {
  },
  mounted(){
    this.getMySites()
    this.sortedAppointment()
  }
};
</script>
<style lang="scss" scoped>
.composant {
    display: none;
}
.image{
  // position:relative;
  background: red;
  width: 100px;
  max-height: 50px;

  img{
    // position:absolute;
    // top:0;
    width: 100%;
  }
}

</style>